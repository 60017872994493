export default class packUp {
  departureBeginDate = null
  departureBeginTime = null
  departureEndTime = null
  departurePlaceName = null
  arrivalBeginDate = null
  arrivalBeginTime = null
  arrivalEndTime = null
  arrivalPlaceName = null
  blockName = null
  blockCode = null
  planningGroupName = null
  planningOrderId = 0
  weight = 0
  volume = 0
  assignVehicleId = null
  assignVehicleCode = null
  assignVehicleName = null
  vehicleCategoryName = null
  vehicleModelName = null
  entryKind = '0'
  maxAvailableEntrySizeName = null
  restQuantity = 0
  totalQuantity = 0
  tableItems = []
  divTableItems = []
}
