<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <div
          v-if="buttonItems"
          class="ml-1 mr-1 mb-3 row justify-content-between"
        >
          <div class="mb-2" v-for="(group, index) in buttonItems" :key="index">
            <BaseButton
              v-for="(item, index) in group"
              :key="index"
              class="mr-2"
              :variant="item.variant"
              :icon="item.icon"
              :columnInfo="item.columnInfo"
              :disabled="item.disabled"
              @click="item.onClickCallback"
            />
          </div>
          <!-- テーブル -->
          <BaseTable selectMode="single" :fields="fields" :items="tableItems" />
        </div>
        <section data-class="work-list-table">
          <!-- <p>
            {{ detailInfo.arrival_max_available_entry_size_name.label }}:
            {{ maxAvailableEntrySizeName }}
          </p> -->
          <p>{{ detailInfo.rest_quantity.label }}: {{ getRestQuantity }}</p>
          <p>{{ detailInfo.total_quantity.label }}: {{ getTotalQuantity }}</p>
          <p>{{ detailInfo.rest_weight.label }}: {{ getRestWeight }}</p>
          <p>{{ detailInfo.total_weight.label }}: {{ getTotalWeight }}</p>
          <!-- 行追加ボタン -->
          <BaseButton
            class="editRowBtn"
            variant="primary"
            icon="plus-circle"
            :columnInfo="resultControlInfo.row_add"
            @click="addRow(obj)"
          />
          <!-- 行削除ボタン -->
          <BaseButton
            class="editRowBtn"
            variant="primary"
            icon="times-circle"
            :columnInfo="resultControlInfo.row_delete"
            :disabled="rowDeleteDisabledCheck()"
            @click="deleteRow(obj)"
          />
          <!-- テーブル -->
          <b-table
            data-class="base-table"
            class="table table-bordered base-table"
            bordered
            hover
            no-border-collapse
            responsive
            :selectable="true"
            small
            sticky-header
            outlined
            select-mode="single"
            @row-selected="onRowSelected"
            :fields="fields"
            :items="divTableItems"
            v-bind="$attrs"
            v-on="$listeners"
          >
            <template v-slot:cell(quantity)="row">
              <b-form-input
                v-model="row.item.quantity"
                size="sm"
                type="number"
                :formatter="maxInputNumber"
                v-bind="$attrs"
                v-on="$listeners"
                @change="changeRowData(obj)"
                @keypress="onlyNumber($event)"
              />
            </template>
            <template v-slot:cell(weight)="row">
              <b-form-input
                v-model="row.item.weight"
                size="sm"
                type="number"
                :formatter="maxInputNumber"
                v-bind="$attrs"
                v-on="$listeners"
                @change="changeRowData(obj)"
                @keypress="onlyNumber($event)"
              />
            </template>
            <template v-slot:cell(volume)="row">
              <b-form-input
                v-model="row.item.volume"
                size="sm"
                type="number"
                :formatter="maxInputNumber"
                v-bind="$attrs"
                v-on="$listeners"
                @change="changeRowData(obj)"
                @keypress="onlyNumber($event)"
              />
            </template>
            <template v-slot:cell(note)="row">
              <b-form-input
                v-model="row.item.note"
                size="sm"
                type="text"
                :formatter="maxInputNote"
                v-bind="$attrs"
                v-on="$listeners"
              />
            </template>
          </b-table>
        </section>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- クリアボタン -->
        <BaseButton
          variant="primary"
          :columnInfo="resultControlInfo.clear"
          @click="clear(obj)"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!-- 登録モーダル -->
    <ConfirmModal
      id="orderCountSplit-register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
    <!-- 解除モーダル -->
    <ConfirmModal
      id="orderCountSplit-cancel-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="totalCancel(obj)"
    />
    <!-- 情報モーダル -->
    <!-- <InfoModal
      id="orderCountSplit-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back"
    /> -->
    <!-- 車両マスタモーダル -->
    <VehicleModal
      id="orderCountSplit-vehicle-modal"
      type="entry"
      selectMode="single"
      :useUnset="false"
      @after-close-set="afterCloseSetVehicle"
    />
  </div>
</template>
<script>
import packUpInfo from './OrderPackUpInfo'
import { openSubModal } from '@/common/Common.js'
import {
  init,
  vehicleDivision, //車両選択分割ボタン
  //entryDivision, //入構制限分割ボタン
  totalCancel, //全解除ボタン
  goTotalCancel, //全解除ボタン
  clear,
  //back,
  goRegister,
  changeRowData,
  addRow,
  deleteRow,
  register,
} from './OrderCountSplitModal.js'
export default {
  components: {
    VehicleModal: () => import('@/master/vehicle/VehicleModal.vue'),
  },

  data() {
    return {
      tableItems: [], //CurrentList
      divTableItems: [],
      copyData: [],
      selectedItem: [],
      packUpInfoList: new packUpInfo(), //SearchList
      obj: this,
      displayOrderData: {},
      registerMessage: null,
      vehicleDivisionDisabled: false,
      //entryDivisionDisabled: false,
      totalCancelDisabled: false,
      rowDeleteDisabled: false,
      rowAddDisabled: false,
      registDisabled: false,
      maxAvailableEntrySizeName: null,
      restQuantity: 0,
      totalQuantity: 0,
      restWeight: 0,
      totalWeight: 0,
      OrderInitWeight: 0,
      OrderInitVol: 0,
      OrderInitNum: 0,
      OrderMaxNum: 0,
      oriData: new packUpInfo(),
    }
  },

  props: {
    selectedOrderSplitIds: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    selectedOrderSplitIds() {
      init(this)
    },
  },

  computed: {
    // 数量
    getRestQuantity() {
      return this.restQuantity
    },
    getTotalQuantity() {
      return this.totalQuantity
    },
    // 質量
    getRestWeight() {
      return this.restWeight
    },
    getTotalWeight() {
      return this.totalWeight
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.ORDER_COUNT_SPLIT_MODAL
      )('result_ctrl_info')
    },
    detailInfo() {
      let info = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.ORDER_COUNT_SPLIT_MODAL
      )('detail_info')
      return info
    },
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.ORDER_COUNT_SPLIT_MODAL
      )(this.DOMAIN_NAME.FIELDS)
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    infoMessage() {
      return this.$store.getters['init/getMessage']('YZ00MG995I', '登録')
    },
    buttonItems() {
      return [
        // ボタングループ#1
        [],
        [
          // 車両選択分割ボタン
          {
            variant: 'primary',
            columnInfo: this.resultControlInfo.vehicle_division,
            disabled: this.vehicleDivisionDisabled,
            onClickCallback: () => {
              openSubModal(this, 'orderCountSplit-vehicle-modal')
            },
          },
          // // 入構制限分割ボタン
          // {
          //   variant: 'primary',
          //   columnInfo: this.resultControlInfo.entry_division,
          //   disabled: this.entryDivisionDisabled,
          //   onClickCallback: () => {
          //     entryDivision(this)
          //   },
          // },
          // 全解除ボタン
          {
            variant: 'primary',
            columnInfo: this.resultControlInfo.total_cancel,
            disabled: this.totalCancelDisabled,
            onClickCallback: () => {
              goTotalCancel(this)
            },
          },
          // 登録ボタン
          {
            variant: 'primary',
            columnInfo: this.resultControlInfo.register,
            disabled: this.registDisabled,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // 数量分割の制限解除対応で追加 「登録ボタン」
          {
            variant: 'primary',
            columnInfo: this.resultControlInfo.register_new,
            disabled: this.registDisabled,
            onClickCallback: () => {
              goRegister(this)
            },
          },
        ],
      ]
    },
  },

  methods: {
    clear,
    register,
    totalCancel,
    changeRowData,
    addRow,
    deleteRow,
    //back,

    maxInputNumber(value) {
      //金額のMaxLength
      return String(value).substring(0, 9)
    },
    maxInputNote(value) {
      //備考のMaxLength
      return String(value).substring(0, 100)
    },

    //#2670
    //配車オーダーの数量、重量、容量はInt型で設定
    onlyNumber($event) {
      //console.log($event.keyCode) //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot = not allow
        $event.preventDefault()
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    /**
     * 車両マスタ参照から取得データ設定
     */
    afterCloseSetVehicle(vehicleInfos) {
      this.obj.$bvModal.hide('orderCountSplit-vehicle-modal')

      vehicleDivision(this.obj, vehicleInfos)
    },

    /**
     * 行削除ボタン
     */
    rowDeleteDisabledCheck() {
      var result = this.rowDeleteDisabled
      if (!result) {
        result = !this.oneSelected()
      }
      return result
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    oneSelected() {
      return this.selectedItem.length === 1
    },
  },
}
</script>
<style lang="scss" scoped>
.editRowBtn {
  margin-right: 3px;
  margin-top: 10px;
  margin-bottom: 3px;
}
.base-table {
  font-size: 0.8rem;
  th,
  td {
    padding: 5px;
    vertical-align: middle;
  }

  th {
    background-color: $primary;
    color: #eee;
    padding: 0 1rem;
    text-align: center;
    width: 130px;
  }
}
</style>
