import { render, staticRenderFns } from "./OrderCountSplitModal.vue?vue&type=template&id=72f80643&scoped=true&"
import script from "./OrderCountSplitModal.vue?vue&type=script&lang=js&"
export * from "./OrderCountSplitModal.vue?vue&type=script&lang=js&"
import style0 from "./OrderCountSplitModal.vue?vue&type=style&index=0&id=72f80643&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72f80643",
  null
  
)

export default component.exports