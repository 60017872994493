import { createAuthenticaionService } from '@/services'
const BASE_URL = `${process.env.VUE_APP_API_URL}planningpackup/`

class PlanningFlagService {
  // 荷纏め
  findDetailPackup(id, idList) {
    return createAuthenticaionService(`${BASE_URL}detailpackup/`).get(
      `/${id}/?ids=${idList}`
    )
  }
  cancelPackup(id, data) {
    return createAuthenticaionService(`${BASE_URL}cancelpackup/`).put(
      `/${id}`,
      data
    )
  }
  editPackup(id, data) {
    return createAuthenticaionService(`${BASE_URL}editpackup/`).put(
      `/${id}`,
      data
    )
  }

  // 配送纏め
  findDetailDelivery(id, idList) {
    return createAuthenticaionService(`${BASE_URL}detaildelivery/`).get(
      `/${id}/?ids=${idList}`
    )
  }
  cancelDelivery(id, data) {
    return createAuthenticaionService(`${BASE_URL}canceldelivery/`).put(
      `/${id}`,
      data
    )
  }
  editDelivery(id, data) {
    return createAuthenticaionService(`${BASE_URL}editdelivery/`).put(
      `/${id}`,
      data
    )
  }

  //数量分割
  findDetailPartition(id, entryKind) {
    return createAuthenticaionService(`${BASE_URL}detailpartition/`).get(
      `/${id}/?entryKind=${entryKind}`
    )
  }
  cancelPartition(id, data) {
    return createAuthenticaionService(`${BASE_URL}cancelpartition/`).put(
      `/${id}`,
      data
    )
  }
  editPartition(id, data) {
    return createAuthenticaionService(`${BASE_URL}editpartition/`).put(
      `/${id}`,
      data
    )
  }
  //数量分割の制限解除対応で追加
  newEditPartition(id, data) {
    return createAuthenticaionService(`${BASE_URL}neweditpartition/`).put(
      `/${id}`,
      data
    )
  }

  //区間分割
  findDetailSectionPartition(id, entryKind) {
    return createAuthenticaionService(`${BASE_URL}detailsectionpartition/`).get(
      `/${id}/?entryKind=${entryKind}`
    )
  }
  addSectionPartition(id, data) {
    return createAuthenticaionService(`${BASE_URL}addsectionpartition/`).put(
      `/${id}`,
      data
    )
  }
  delSectionPartition(id, data) {
    return createAuthenticaionService(`${BASE_URL}delsectionpartition/`).put(
      `/${id}`,
      data
    )
  }
  resetSectionPartition(id, data) {
    return createAuthenticaionService(`${BASE_URL}resetsectionpartition/`).put(
      `/${id}`,
      data
    )
  }
  cancelSectionPartition(id, data) {
    return createAuthenticaionService(`${BASE_URL}cancelsectionpartition/`).put(
      `/${id}`,
      data
    )
  }
}

export default new PlanningFlagService()
