import {
  openMsgModal,
  openSubModal,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import { MSG_TYPE, ENTRY_KIND, LIMITED_MAX_MIN } from '@/common/const.js'
import PlanningFlagService from './PlanningFlagService'
import _ from 'lodash'

export function init(obj) {
  if (obj.selectedOrderSplitIds.length > 0) {
    obj.$store.dispatch('init/setServiceLoading')
    PlanningFlagService.findDetailPartition(
      obj.selectedOrderSplitIds[1], //id
      obj.selectedOrderSplitIds[0] //entryKind
    )
      .then(
        (response) => {
          var getDatas = _.cloneDeep(response.data)
          var datas = convertSnakeCace(getDatas)

          obj.tableItems = []
          obj.tableItems = [datas[0]]
          obj.packUpInfoList.tableItems = [datas[0]] //save oridata
          //obj.packUpInfoList.divTableItems = null
          obj.divTableItems = []
          var divData = _.cloneDeep(datas)
          divData.splice(0, 1)
          obj.divTableItems = divData
          obj.packUpInfoList.divTableItems = divData //save oridata
          if (obj.selectedOrderSplitIds[0] == ENTRY_KIND.INSERT) {
            //数量分割 ENTRY_KIND.NEW
            obj.vehicleDivisionDisabled = false //車両選択分割
            //obj.entryDivisionDisabled = false //入構制限分割

            obj.totalCancelDisabled = true //全解除
            // obj.tableItems.forEach((element) => {
            //   // 入構制限分割
            //   // →該当する入構制限なし時は非活性とする。
            //   if (element.arrival_max_available_entry_size == null) {
            //     obj.entryDivisionDisabled = true //入構制限分割
            //   }
            // })
          } else {
            //数量分割編集 ENTRY_KIND.UPDATE
            obj.vehicleDivisionDisabled = true //車両選択分割
            //obj.entryDivisionDisabled = true //入構制限分割

            obj.totalCancelDisabled = false //全解除
          }

          var nNum = obj.tableItems[0].quantity
          if (nNum == 0) {
            nNum = 1
          }
          obj.OrderInitNum = 1
          obj.OrderMaxNum = nNum
          obj.OrderInitWeight = Math.floor(obj.tableItems[0].weight / nNum)
          obj.OrderInitVol = Math.floor(obj.tableItems[0].volume / nNum)
          // 車両選択時、質量で割り算するため、0の値を許可しないこと。
          if (obj.OrderInitWeight == 0) {
            obj.OrderInitWeight = 1
          }

          displayData(obj)
          obj.oriData = _.cloneDeep(obj.packUpInfoList)
        },
        (e) => {
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else {
            msg = e.response.data.message
          }
          // エラーモーダル表示
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

/**
 * 総数量と総質量の再計算処理
 * @param {} obj
 */
export function changeRowData(obj) {
  // 単品分割
  //  数量・質量・容量→入力可能
  if (obj.divTableItems.length > 0) {
    let nTotalNum = obj.totalQuantity
    let nBuktSumNum = 0
    let nTotalWeight = obj.totalWeight
    let nBuktSumWeight = 0

    obj.divTableItems.forEach((element) => {
      if (element.quantity == null) {
        element.quantity = 0
      }
      let dataNum = Number(element.quantity)
      nBuktSumNum = nBuktSumNum + dataNum

      if (element.weight == null) {
        element.weight = 0
      }
      let dataWeight = Number(element.weight)
      nBuktSumWeight = nBuktSumWeight + dataWeight
    })
    obj.restQuantity = nTotalNum - nBuktSumNum
    obj.restWeight = nTotalWeight - nBuktSumWeight
  } else {
    if (obj.tableItems.length > 0) {
      let totalNum = 0
      let totalWeight = 0
      obj.tableItems.forEach((element) => {
        totalNum += element.quantity
        totalWeight += element.weight
      })
      obj.restQuantity = totalNum
      obj.totalQuantity = totalNum
      obj.restWeight = totalWeight
      obj.totalWeight = totalWeight
    }
  }
}

/**
 * データをコントロールに表示する。
 * @param {*} obj
 */
function displayData(obj) {
  //var result = false
  displayNum(obj)
  let iRow = 0

  //検索条件ラベルの表示
  obj.tableItems.forEach((element) => {
    obj.tableItems[iRow].no = iRow + 1
    // 取消行の背景色変更
    if (element.delete_status == '1') {
      //backgroundColor:gray
    }
    iRow++
  })

  iRow = 0

  //検索条件ラベルの表示
  obj.divTableItems.forEach((element) => {
    obj.divTableItems[iRow].no = iRow + 1
    // 取消行の背景色変更
    if (element.delete_status == '1') {
      //backgroundColor:gray
    }
    iRow++
  })

  //obj.detailCtrlInfo.clear.visibility = this.isDisable
}

function displayNum(obj) {
  let nTotalNum = 0
  let nBuktSumNum = 0
  let nTotalWeight = 0
  let nBuktSumWeight = 0
  var sNykuSignNm = ''

  obj.tableItems.forEach((element) => {
    nTotalNum = element.quantity
    nTotalWeight = element.weight
    sNykuSignNm = element.arrival_max_available_entry_size
  })

  obj.divTableItems.forEach((element) => {
    nBuktSumNum = nBuktSumNum + Number(element.quantity)
    nBuktSumWeight = nBuktSumWeight + Number(element.weight)
  })

  obj.maxAvailableEntrySizeName = sNykuSignNm
  // 数量
  obj.restQuantity = nTotalNum - nBuktSumNum
  obj.totalQuantity = nTotalNum
  // 質量
  obj.restWeight = nTotalWeight - nBuktSumWeight
  obj.totalWeight = nTotalWeight
}

export function clear(obj) {
  obj.packUpInfoList = _.cloneDeep(obj.oriData)
  obj.packUpInfoList.tableItems = _.cloneDeep(obj.oriData.tableItems)
  obj.tableItems = _.cloneDeep(obj.oriData.tableItems)
  obj.divTableItems = _.cloneDeep(obj.oriData.divTableItems)
}

/**
 * 全解除ボタン
 * @param {*} obj
 */
export function goTotalCancel(obj) {
  // {0}処理を行います。よろしいですか？
  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.total_cancel.label
  )
  openSubModal(obj, 'orderCountSplit-cancel-modal')
}

/**
 * 全解除
 * @param {*} obj
 */
export function totalCancel(obj) {
  obj.$bvModal.hide('orderCountSplit-cancel-modal')

  var camelPattern = convertCamelCace(obj.tableItems)

  var divCamelPattern = convertCamelCace(obj.divTableItems)

  var result = true
  // 「荷纏め＝なし」
  let registerList = {
    entryKind: obj.selectedOrderSplitIds[0],
    dtoData: camelPattern[0],
    dtoList: divCamelPattern,
  }

  if (result) {
    obj.$store.dispatch('init/setServiceLoading')
    PlanningFlagService.cancelPartition(
      obj.selectedOrderSplitIds[1],
      registerList
    )
      .then(
        () => {
          openMsgModal(
            obj,
            MSG_TYPE.I,
            obj.$store.getters['init/getMessage'](
              'YZ00MG995I',
              obj.resultControlInfo.total_cancel.label
            )
          )
          obj.$bvModal.hide('order-count-split-modal')
          obj.$emit('after-close-set')
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else {
            msg = e.response.data.message + e.response.data.messageDetail
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

/**
 * 行削除
 * @param {*} obj
 */
export function deleteRow(obj) {
  if (obj.selectedItem.length == 0) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG969E')
    )
    return
  }
  // 数量分割の行削除処理
  //１行選択
  // もし複数件⇒For文
  obj.selectedItem.forEach((element) => {
    const index = obj.divTableItems.indexOf(element)
    obj.divTableItems.splice(index, 1)
  })

  changeRowData(obj)
}

/**
 * 行追加
 * @param {*} obj
 */
export function addRow(obj) {
  // 数量分割の行追加処理
  //上段のデータをコピーする。
  // （配車番号を空白とする。）
  obj.tableItems.forEach((element) => {
    let copyData = _.cloneDeep(element)

    copyData.planning_order_id = null
    let iOrderTotalNum = 0
    let iOrderTotalWeight = 0
    let iOrderTotalVol = 0

    obj.divTableItems.forEach((divElement) => {
      iOrderTotalNum += Number(divElement.quantity)
      iOrderTotalWeight += Number(divElement.weight)
      iOrderTotalVol += Number(divElement.volume)
    })
    // 分割数量＝０ 許容
    //if (copyData.quantity - iOrderTotalNum > 0) {
    //}
    // 分割質量（重量）
    // 分割質量の合算でチェックするため、分割質量＝０ 許容する。
    if (copyData.weight - iOrderTotalWeight >= 0) {
      copyData.quantity = element.quantity - iOrderTotalNum
      copyData.weight = element.weight - iOrderTotalWeight
      copyData.volume = element.volume - iOrderTotalVol
      obj.divTableItems.push(copyData)
    }
  })

  changeRowData(obj)
}

/**
 * 「車両選択分割」ボタン
 * @param {*} obj
 */
export function vehicleDivision(obj, vehicleInfos) {
  // 遷移データ：運送会社ID（※運送会社参照にて取得している場合)

  //オーダ数量分割画面で車両選択分割した場合は、
  //分割したオーダの指定運送会社ID、指定運送会社IDに選択した車両情報を設定する。
  obj.tableItems.forEach((element) => {
    let copyData = _.cloneDeep(element)

    copyData.planning_order_id = null

    // 車両情報
    // ①車両
    copyData.vehicleId = vehicleInfos.vehicle_id
    copyData.vehicleCode = vehicleInfos.vehicle_code
    copyData.vehicleName = vehicleInfos.vehicle_name

    // ②運送会社
    copyData.assignCarrierId = vehicleInfos.carrier_id
    copyData.assignCarrierCode = vehicleInfos.carrier_code
    copyData.assignCarrierName = vehicleInfos.carrier_name

    var nDecMaxWeight = vehicleInfos.max_loading_weight
    if (nDecMaxWeight > LIMITED_MAX_MIN.Max) {
      nDecMaxWeight = LIMITED_MAX_MIN.Max
    }

    var bunkatuNum = Math.floor(nDecMaxWeight / obj.OrderInitWeight)
    //max_loading_volume
    let nOrderNum = 0
    obj.tableItems.forEach((element) => {
      nOrderNum = nOrderNum + element.quantity
    })

    let nTotalSum = 0
    obj.divTableItems.forEach((element) => {
      nTotalSum = nTotalSum + Number(element.quantity)
    })

    var nAmariSuryou = nOrderNum - nTotalSum
    // 残数量と比較して設定する。
    if (nAmariSuryou > bunkatuNum) {
      copyData.quantity = bunkatuNum
      copyData.weight = bunkatuNum * obj.OrderInitWeight
      copyData.volume = bunkatuNum * obj.OrderInitVol
    } else {
      copyData.quantity = nAmariSuryou
      copyData.weight = nAmariSuryou * obj.OrderInitWeight
      copyData.volume = nAmariSuryou * obj.OrderInitVol
    }

    if (copyData.quantity > 0) {
      obj.divTableItems.push(copyData)
    } else {
      // {0}分割出来ませんでした。条件を見直してください。
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('EB05S001_MG005E', '車両数量')
      )
    }
  })

  changeRowData(obj)
}

// /**
//  * 「入構制限分割」ボタン
//  * @param {*} obj
//  */
// export function entryDivision(obj) {
//   // 入構制限分割の行追加処理
//   //上段のデータをコピーする。
//   // （配車番号を空白とする。）

//   // 卸地場所情報
//   var nMaxWeight = 0

//   obj.tableItems.forEach((element) => {
//     nMaxWeight = Number(element.arrival_max_available_entry_size)
//     if (nMaxWeight > 0) {
//       var bunkatuNum = Math.floor(nMaxWeight / obj.OrderInitWeight)
//       if (bunkatuNum > 0) {
//         obj.divTableItems = [] //clear
//         var nOrderNum = element.quantity
//         for (var i = 1; ; i++) {
//           let copyData = _.cloneDeep(element)
//           copyData.planning_order_id = null
//           copyData.no = i
//           // 残数量と比較して設定する。
//           if (nOrderNum > bunkatuNum) {
//             copyData.quantity = bunkatuNum
//             copyData.weight = bunkatuNum * obj.OrderInitWeight
//             copyData.volume = bunkatuNum * obj.OrderInitVol
//           } else {
//             copyData.quantity = nOrderNum
//             copyData.weight = nOrderNum * obj.OrderInitWeight
//             copyData.volume = nOrderNum * obj.OrderInitVol
//           }
//
//           obj.divTableItems.push(copyData)
//           // 残
//           nOrderNum = nOrderNum - bunkatuNum
//           if (nOrderNum <= 0) {
//             break
//           }
//         }
//       }
//     } else {
//       // 入構制限の最大積載質量が取得できなかったため、入構制限分割は出来ません。
//       var msg = obj.$store.getters['init/getMessage']('EB05S001_MG003E')
//       openMsgModal(obj, MSG_TYPE.E, msg)
//     }
//   })
//   changeRowData(obj)
// }

/**
 * 「登録」ボタン
 * @param {*} obj
 */
export function goRegister(obj) {
  // {0}処理を行います。よろしいですか？
  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.register.label
  )
  openSubModal(obj, 'orderCountSplit-register-modal')
}

export function register(obj) {
  obj.$bvModal.hide('orderCountSplit-register-modal')

  var registerResult = registerCheck(obj)
  if (registerResult) {
    var camelPattern = convertCamelCace(obj.tableItems)

    var divCamelPattern = convertCamelCace(obj.divTableItems)

    var result = true
    // 「荷纏め＝なし」
    if (obj.tableItems.length == 1) {
      let registerList = {
        entryKind: obj.selectedOrderSplitIds[0],
        dtoData: camelPattern[0],
        dtoList: divCamelPattern,
      }

      if (result) {
        obj.$store.dispatch('init/setServiceLoading')
        // 数量分割の制限解除対応で既存処理をコメント
        // PlanningFlagService.editPartition(
        PlanningFlagService.newEditPartition(
          obj.selectedOrderSplitIds[1],
          registerList
        )
          .then(
            () => {
              openMsgModal(
                obj,
                MSG_TYPE.I,
                obj.$store.getters['init/getMessage'](
                  'YZ00MG995I',
                  obj.resultControlInfo.register.label
                )
              )
              obj.$bvModal.hide('order-count-split-modal')
              obj.$emit('after-close-set')
            },
            (e) => {
              // エラーモーダル表示
              var msg
              if (e.response == undefined) {
                msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
              } else {
                msg = e.response.data.message + e.response.data.messageDetail
              }
              openMsgModal(obj, MSG_TYPE.E, msg)
            }
          )
          .finally(() => {
            obj.$store.dispatch('init/setServiceUnLoading')
          })
      }
    }
  }
}

/**
 * 登録処理前check
 * @param {*} obj
 */
function registerCheck(obj) {
  var errorMsg = true
  var nOrderNum = 0
  var nOrderVol = 0
  var nOrderWeight = 0
  obj.tableItems.forEach((element) => {
    nOrderNum = nOrderNum + element.quantity
    nOrderVol = nOrderVol + element.volume
    nOrderWeight = nOrderWeight + element.weight
  })

  var nTotalSum = 0
  var nTotalWeight = 0
  obj.divTableItems.forEach((element) => {
    nTotalSum = nTotalSum + Number(element.quantity)
    nTotalWeight = nTotalWeight + Number(element.weight)
  })
  var nAmariWeight = nOrderWeight - nTotalWeight

  // 分割質量の合計＝一致
  //選択されているかチェック
  // 2件以上、または 1件かつあまりあり。
  if (
    obj.divTableItems.length >= 2 ||
    (obj.divTableItems.length == 1 && nAmariWeight > 0)
  ) {
    if (nAmariWeight > 0) {
      obj.tableItems.forEach((element) => {
        var copyItems = _.cloneDeep(element)
        copyItems.planning_order_id = null
        copyItems.quantity = 0
        copyItems.weight = nAmariWeight
        copyItems.volume = 0
        obj.divTableItems.push(copyItems)
        return
      })
    }
  } else {
    // 分割情報より行を【{0}】選択してください。
    var msg = obj.$store.getters['init/getMessage'](
      'EB05S001_MG001E',
      '2件以上'
    )
    openMsgModal(obj, MSG_TYPE.E, msg)
    errorMsg = false
  }

  //var nAmariSurtou = nOrderNum - nTotalSum
  //
  // //選択されているかチェック
  // // 2件以上、または 1件かつあまりあり。
  // if (
  //   obj.divTableItems.length >= 2 ||
  //   (obj.divTableItems.length == 1 && nAmariSurtou > 0)
  // ) {
  //   if (nAmariSurtou > 0) {
  //     obj.tableItems.forEach((element) => {
  //       var copyItems = _.cloneDeep(element)
  //       copyItems.planning_order_id = null
  //       copyItems.quantity = nAmariSurtou
  //       copyItems.weight = nAmariSurtou * obj.OrderInitWeight
  //       copyItems.volume = nAmariSurtou * obj.OrderInitVol
  //       obj.divTableItems.push(copyItems)
  //       return
  //     })
  //   }
  // } else {
  //   // 分割情報より行を【{0}】選択してください。
  //   var msg = obj.$store.getters['init/getMessage'](
  //     'EB05S001_MG001E',
  //     '2件以上'
  //   )
  //   openMsgModal(obj, MSG_TYPE.E, msg)
  //   errorMsg = false
  // }

  let nNum = 0
  let nVol = 0
  let nWeight = 0
  let nResetWeight = 0
  obj.divTableItems.forEach((element) => {
    let nTmpNum = 0
    let nTmpVol = 0
    let nTmpWeight = 0
    nTmpNum = Number(element.quantity)
    nTmpVol = Number(element.volume)
    nTmpWeight = Number(element.weight)
    // 分割数量＝０ 許容
    // if (LIMITED_MAX_MIN.MIN >= nTmpNum) {
    //   openMsgModal(
    //     obj,
    //     MSG_TYPE.E,
    //     obj.$store.getters['init/getMessage']('EB05S001_MG001E', '分割数量、0')
    //   )
    //   errorMsg = false
    //   return errorMsg
    // }
    //
    // 分割質量（重量）
    // 分割質量＝０ 許容
    // [ST2700]→「質量＝０」では１でリセット（DB）
    if (LIMITED_MAX_MIN.MIN > nTmpWeight) {
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('EB05S001_MG001E', '分割質量、0')
      )
      errorMsg = false
      return errorMsg
    }

    nNum = nNum + nTmpNum
    nVol = nVol + nTmpVol
    nWeight = nWeight + nTmpWeight
    // [ST2700]→誘導車
    if (nTmpWeight == 0) {
      nResetWeight = nResetWeight + 1
    } else {
      nResetWeight = nResetWeight + nTmpWeight
    }

    if (LIMITED_MAX_MIN.MAX < nNum) {
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('EB03S001_MG001E', '数量', '登録')
      )
      errorMsg = false
      return errorMsg
    }
    if (LIMITED_MAX_MIN.MAX < nWeight) {
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('EB03S001_MG001E', '質量', '登録')
      )
      errorMsg = false
      return errorMsg
    }
    if (LIMITED_MAX_MIN.MAX < nVol) {
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('EB03S001_MG001E', '容量', '登録')
      )
      errorMsg = false
      return errorMsg
    }
  })

  //分割合計と元受注情報をチェックする。
  // 数量、質量、容量
  // 【数量】チェックなし
  // if (nNum > nOrderNum) {
  //   openMsgModal(
  //     obj,
  //     MSG_TYPE.E,
  //     obj.$store.getters['init/getMessage'](
  //       'EB03S001_MG001E',
  //       '受注数量',
  //       '登録'
  //     )
  //   )
  //   errorMsg = false
  // }
  //--------------------->>
  //if (nWeight > nOrderWeight) { }
  // [ST2700]→数量分割画面の重量チェック処理
  //分割前の重量＋（分割後のオーダ数－１）＞＝ＳＵＭ（分割後の重量）
  //ＳＵＭ（分割後の重量）＞＝分割前の重量
  // ==>>ＳＵＭ（分割後の重量） > 分割前の重量＋（分割後のオーダ数－１） = ERROR
  // ==>>分割前の重量 > ＳＵＭ（分割後の重量） = ERROR
  let orderLength = obj.divTableItems.length
  //console.log('nResetWeight===>' + nResetWeight)
  //分割前の重量 = nOrderWeight
  //ＳＵＭ（分割後の重量） = nResetWeight
  //分割後のオーダ数 = orderLength
  //---------------------<<
  if (
    nResetWeight > nOrderWeight + (orderLength - 1) ||
    nOrderWeight > nResetWeight
  ) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage'](
        'EB03S001_MG001E',
        '受注質量',
        '登録'
      )
    )
    errorMsg = false
  }
  // 【容量】チェックなし
  // if (nVol > nOrderVol) {
  //   openMsgModal(
  //     obj,
  //     MSG_TYPE.E,
  //     obj.$store.getters['init/getMessage'](
  //       'EB03S001_MG001E',
  //       '受注容量',
  //       '登録'
  //     )
  //   )
  //   errorMsg = false
  // }

  return errorMsg
}
